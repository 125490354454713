<template>
  <CollapsibleLinks>
    <template #trigger="{ toggle }">
      <a
        class="font-bold text-primary hover:text-primary-dark text-lg"
        @click.stop.prevent="toggle"
        >{{ $t("branding.about") }}</a
      >
    </template>
    <div class="flex flex-col mb-3 gap-1 text-sm">
      <NuxtLink
        v-if="!isSuperprofikCz"
        :to="localePath({ name: 'aboutUs' }) + '#impressum'"
        class="text-white"
      >
        {{ $t("footer.aboutus") }}
      </NuxtLink>
      <a v-if="!isSuperprofikCz" href="karrier" class="text-white">{{
        $t("footer.carreer")
      }}</a>
      <a href="blog" target="_blank" class="text-white">{{
        $t("footer.blog")
      }}</a>
      <NuxtLink
        :to="localePath({ name: 'questionsPage' })"
        target="_blank"
        class="text-white"
        >{{ $t("footer.questions") }}</NuxtLink
      >
      <a
        v-if="!isSuperprofikCz"
        href="https://superprofik.cz"
        target="_blank"
        class="text-white"
      >
        {{ $t("footer.superprofik") }}
      </a>
      <NuxtLink
        v-if="!isSuperprofikCz"
        :to="localePath({ name: 'inspirationLandingPage' })"
        class="text-white"
        >{{ $t("footer.inspirations") }}</NuxtLink
      >
    </div>
  </CollapsibleLinks>
</template>

<script>
import CollapsibleLinks from "./CollapsibleLinks.vue";
import { useBrand } from "~/composables/useBrand";

export default {
  components: { CollapsibleLinks },
  setup() {
    const { isSuperprofikCz } = useBrand();
    return { isSuperprofikCz };
  },
};
</script>
