<template>
  <div class="flex flex-col mb-4">
    <NuxtLink
      class="text-2xl font-bold text-primary-lightest hover:text-primary-lighter text-center"
      :to="countyLink"
    >
      {{ county.name }}
    </NuxtLink>

    <NuxtLink
      class="text-xl font-bold text-white hover:text-primary-lighter text-center"
      :to="largetsCityLink"
    >
      {{ county.largestCity.name }}
    </NuxtLink>

    <NuxtLink
      v-if="profession"
      class="text-1xl font-bold text-white hover:text-primary-lighter text-center"
      :to="countyLink"
    >
      {{ $t("personList.allInCounty") }}
    </NuxtLink>
  </div>
</template>

<script>
export default {
  props: {
    county: {
      type: Object,
      required: true,
    },
    profession: {
      type: Object,
      default: null,
    },
  },
  computed: {
    countyLink() {
      if (this.profession) {
        return this.localePath({
          name: "professionals",
          params: {
            profession: this.profession.seoName,
            city: this.county.seoName,
          },
        });
      }
      return this.localePath({
        name: "county",
        params: { county: this.county.seoName },
      });
    },
    largetsCityLink() {
      if (this.profession) {
        return this.localePath({
          name: "professionals",
          params: {
            profession: this.profession.seoName,
            city: this.county.largestCity.seoName,
          },
        });
      }

      return this.localePath({
        name: "countyCity",
        params: {
          county: this.county.seoName,
          city: this.county.largestCity.seoName,
        },
      });
    },
  },
};
</script>
