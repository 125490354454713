<template>
  <footer class="bg-dark-blue text-white text-center md:text-left w-full">
    <div
      class="flex flex-col-reverse md:flex-row p-3 py-12 gap-0 md:gap-8 lg:gap-20 xl:gap-32 2xl:gap-36 md:max-w-[95%] xl:max-w-6xl 2xl:max-w-7xl mx-auto"
    >
      <div
        class="FooterBranding flex flex-col gap-4 items-center md:items-start"
      >
        <JoszakiLogo sizing="footer" />
        <div class="flex">
          <LogoSocial v-if="facebookUrl" :url="facebookUrl" logo="facebook-f" />
          <LogoSocial v-if="twitterUrl" :url="twitterUrl" logo="twitter" />
        </div>
      </div>
      <div class="flex-1 grid grid-cols-1 gap-4 md:grid-cols-[1fr_1fr_1fr_1fr]">
        <FooterLinksAbout />
        <FooterLinksContent />
        <FooterLinksUseful />
        <FooterLinksForMates />
      </div>
    </div>
    <div class="bg-dark-blue-darker p-3 text-center">
      {{
        $t("footer.since", {currentYear: new Date().getFullYear()}),
      }}
    </div>
  </footer>
</template>

<script>
import FooterLinksAbout from "./footer/FooterLinksAbout.vue";
import FooterLinksContent from "./footer/FooterLinksContent.vue";
import FooterLinksForMates from "./footer/FooterLinksForMates.vue";
import FooterLinksUseful from "./footer/FooterLinksUseful.vue";
import LogoSocial from "./footer/LogoSocial.vue";
import JoszakiLogo from "./img/JoszakiLogo.vue";

export default {
  components: {
    JoszakiLogo,
    LogoSocial,
    FooterLinksAbout,
    FooterLinksContent,
    FooterLinksUseful,
    FooterLinksForMates,
  },
  setup() {
    return {
      twitterUrl: process.env.TWITTER_PAGE_URL,
      facebookUrl: process.env.FACEBOOK_PAGE_URL,
    };
  },
};
</script>
