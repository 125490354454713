<template>
  <div class="bg-primary-darker py-14 hidden md:flex justify-center">
    <div class="container flex flex-col">
      <div class="flex flex-col justify-center">
        <div class="flex align-middle justify-center pb-3 text-5xl">
          <IconComponent class="text-white" icon="map-marker-alt" />
        </div>
        <div class="flex align-middle justify-center text-white text-2xl">
          {{ $t("personList.cities") }}
        </div>
      </div>
      <div class="flex flex-row gap-10">
        <div class="flex-1">
          <div class="flex flex-col mb-4">
            <NuxtLink
              :to="capitalLink"
              class="text-2xl font-bold text-primary-lightest hover:text-primary-lighter text-center"
            >
              {{ $t("personList.capital") }}
            </NuxtLink>
          </div>
          <div v-for="county in firstCounties" :key="county.countySeoName">
            <CityLink :county="county" :profession="profession" />
          </div>
        </div>
        <div
          v-for="(countyList, index) in restOfCounties"
          :key="index"
          class="flex-1"
        >
          <div v-for="county in countyList" :key="county.countySeoName">
            <CityLink :county="county" :profession="profession" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CityLink from "./CityLink.vue";
import useCapital from "~/composables/useCapital";

const SPLIT_SIZE = 5;

export default {
  components: {
    CityLink,
  },
  setup() {
    const { capitalSeoName, capitalCounty } = useCapital();
    return {
      capitalSeoName,
      capitalCounty,
    };
  },
  computed: {
    counties() {
      return this.$store?.getters["data/counties"];
    },
    profession() {
      return this.$store?.getters["listPage/profession"];
    },
    filteredCounties() {
      return this.counties.filter((x) => {
        return x.id !== this.capitalCounty?.id;
      });
    },
    restOfCounties() {
      const restCounties = this.filteredCounties.slice(SPLIT_SIZE - 1);

      const result = [];

      for (let i = 0; i < restCounties.length; i += SPLIT_SIZE) {
        result.push(restCounties.slice(i, i + SPLIT_SIZE));
      }

      return result;
    },
    firstCounties() {
      return this.filteredCounties.slice(0, SPLIT_SIZE - 1);
    },
    capitalLink() {
      if (this.profession) {
        return this.localePath({
          name: "professionals",
          params: {
            profession: this.profession.seoName,
            city: this.capitalSeoName,
          },
        });
      }

      return this.localePath({
        name: "county",
        params: { county: this.capitalSeoName },
      });
    },
  },
};
</script>
